import React, { FC, useEffect, useRef, useState } from 'react'

import { useLojinhaContext } from '@lojinha/context'
import { useOnScreen } from '@lojinha/helpers'
import { useFreeShipping } from '@lojinha/location/src/free-shipping/free-shipping.hooks'
import {
  IcmsEnumComponentcontainerdisplayCardsize as CardSize,
  CmsComponentContainerDisplay,
  DistributionCentersSellingStatus,
  MarketableKind,
  SellingStatus,
} from '@lojinha/palantir'
import { CarouselDisplay } from '@lojinha/vitrine/src/components/carousel-display'
import { ListDisplay } from '@lojinha/vitrine/src/components/list-display'
import { t } from '@lojinha/vitrine/src/dictionary'
import { sortedItemsByAvailability } from '@lojinha/vitrine/src/helpers/products'
import { KitPresentation } from '@lojinha/vitrine/src/modules/display/kit-presentation'
import {
  onArrowSlideClick,
  onItemClick,
  onRenderItem,
  onRenderList,
  onSeeAllClick,
} from '@lojinha/vitrine/src/modules/display/logic'
import { OriginList } from '@lojinha/vitrine/src/modules/display/types'
import { DisplayItems } from '@lojinha/vitrine/src/modules/page-content/page-content.types'
import { applyFreeShippingTagToItem } from './free-shipping-tag'

export const Display: FC<
  Omit<CmsComponentContainerDisplay, '_id' | 'display'>
> = ({ id, title, redirectUrl, home, subcategory, cardSize, items }) => {
  const [itemsWithAvailability, setItemsWithAvailability] = useState<
    DisplayItems[]
  >([])
  const {
    centerId,
    freeShippingValue,
    setFreeShippingValue,
    setLoadingFreeShippingValue,
  } = useLojinhaContext()
  const { getFreeShippingValue } = useFreeShipping({
    setFreeShippingValue,
    setLoadingFreeShippingValue,
  })
  const displayRef = useRef<HTMLDivElement>(null)
  const hasSentListViewForKitDisplay = useRef(false)
  const { isRendered } = useOnScreen(displayRef)

  useEffect(() => {
    const isHome = window?.location.pathname === '/'

    if (isRendered && itemsWithAvailability?.length && !isHome) {
      onRenderList({
        id,
        name: title ?? '',
        items: itemsWithAvailability,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRendered, itemsWithAvailability])

  const isAvailableItem = (
    sellingStatus: SellingStatus,
    distributionCentersSellingStatus: DistributionCentersSellingStatus[],
    distributionCenterId: string | null
  ) => {
    const isSelling = sellingStatus === SellingStatus.Selling.toLowerCase()
    if (!isSelling) return false
    if (!distributionCenterId) return true

    const dcStatus = distributionCentersSellingStatus.find(
      dc => dc.centerId === distributionCenterId
    )

    if (!dcStatus) return true

    return dcStatus.sellingStatus === 'active'
  }

  useEffect(() => {
    if (!freeShippingValue) {
      getFreeShippingValue()
    }
  }, [freeShippingValue]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!items?.length) {
      setItemsWithAvailability([])
    } else {
      const itemsWithIsAvailable = items?.map(item => {
        const displayItem = {
          ...item,
          isAvailable: isAvailableItem(
            item.sellingStatus,
            item.distributionCentersSellingStatus,
            centerId || localStorage.getItem('nearest_DC')
          ),
        }

        if (freeShippingValue) {
          return applyFreeShippingTagToItem(displayItem, freeShippingValue)
        }
        return displayItem
      })

      setItemsWithAvailability(sortedItemsByAvailability(itemsWithIsAvailable))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, centerId, freeShippingValue])

  useEffect(() => {
    const isHome = window?.location.pathname === '/'

    if (!isHome || hasSentListViewForKitDisplay.current) {
      return
    }

    if (
      !itemsWithAvailability?.length ||
      itemsWithAvailability[0]?.kind.toUpperCase() !== MarketableKind.Kit
    ) {
      return
    }

    onRenderList({
      id,
      name: title ?? '',
      items: itemsWithAvailability,
    })

    hasSentListViewForKitDisplay.current = true
  }, [id, itemsWithAvailability, title])

  if (!items?.length) return null

  const kind = items[0].kind.toUpperCase()
  const isKit = kind === MarketableKind.Kit
  const kindMealOrProduct =
    kind === MarketableKind.Product || kind === MarketableKind.Meal

  if (isKit && home) {
    return (
      <div ref={displayRef}>
        <KitPresentation
          title={t.kitTitle}
          description={t.kitDescription}
          items={itemsWithAvailability}
          slug={redirectUrl || ''}
          onItemClick={onItemClick}
          onRenderItem={onRenderItem}
          originList={OriginList.LIST}
        />
      </div>
    )
  }

  if (isKit || subcategory) {
    return (
      <div ref={displayRef}>
        <ListDisplay
          id={id}
          items={itemsWithAvailability}
          name={title || ''}
          slug={redirectUrl || ''}
          kind={kind}
          onRenderItem={onRenderItem}
          onItemClick={onItemClick}
          originList={OriginList.CATEGORY}
        />
      </div>
    )
  }

  if (kindMealOrProduct) {
    return (
      <div ref={displayRef}>
        <CarouselDisplay
          id={id}
          showLastCard={items.length === 7}
          items={itemsWithAvailability}
          name={title || ''}
          slug={redirectUrl || ''}
          cardSize={cardSize || CardSize.Default}
          onArrowSlideClick={onArrowSlideClick}
          onSeeAllClick={onSeeAllClick}
          onRenderItem={onRenderItem}
          onItemClick={onItemClick}
          originList={OriginList.CAROUSEL}
          kind={kind}
        />
      </div>
    )
  }

  return null
}
